<template>
  <div>
    <c-toolbar />
    <c-navbar />

    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-card class="mx-auto elevation-11 py-4 px-2">
              <v-row justify="center">
                <v-img src="img/indomaret.png" aspect-ratio="3" max-width="220" position="end"></v-img>
                <!-- <v-col cols="4">
                </v-col>-->
              </v-row>

              <v-row>
      <v-dialog
      v-model="notifPindah"
      persistent
      max-width="700"
    >
      <v-card >
        <v-card-title class="text-h5" justify = "center">
          <v-icon
          color = "info"
          x-large>
            mdi-information
          </v-icon>
          PERHATIAN!
        </v-card-title>
        <v-card-text>
          Anda tidak dapat mengakses menu ini, silakan menginput izin / cuti melalui web portal Indomaret Group Rumah Keduaku pada link berikut dan masuk menggunakan akun ESS Anda.
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-text>
          <a href="https://portal.hrindomaret.com/" target="_blank" style=" color:blue"
              >https://portal.hrindomaret.com/</a>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading = "isLoading"
            color="blue"
            @click="homePage()"
            large
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

              <form class="pa-4">
                <div class="my-2">
                  <v-row justify="center">
                    <v-col cols="12" sm="4">
                    </v-col>

                    <v-card class="mx-auto elevation-11" width="2000">
                      <v-row>
                        <v-col cols="12" sm="12">
                          <v-card-title>
                            Izin Karyawan

                            <v-spacer></v-spacer>
                            <v-col cols="4" sm="4">
                              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                                hide-details></v-text-field>
                            </v-col>
                          </v-card-title>

                          <v-data-table :headers="headerApprove" :items="results" :search="search" :loading="isLoading"
                            loading-text="Loading... Mohon Tunggu" item-key="employeeid" class="elevation-1">

                            <template v-slot:top>
                              <v-toolbar flat>
                                <h5 class="red--text">* Buka Detail Untuk Mengapprove/reject Izin</h5>
                                <v-dialog v-model="dialogDetail" max-width="5000px">
                                  <v-card>

                                    <v-card-text>
                                      <v-data-table v-model="dataDetail" :headers="headerDetail" :items="hasilDetail"
                                        :single-select="singleSelect" :search="searchDetail" :loading="isLoading"
                                        loading-text="Loading... Mohon Tunggu" item-key="FROMD" show-select
                                        class="elevation-1">
                                        <template v-slot:top>
                                          <v-toolbar flat>
                                            <v-toolbar-title>Detail Izin</v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <v-col cols="4" sm="4">
                                              <v-text-field v-model="searchDetail" append-icon="mdi-magnify"
                                                label="Search" single-line hide-details></v-text-field>
                                            </v-col>
                                          </v-toolbar>
                                        </template>

                                      </v-data-table>

                                    </v-card-text>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-row justify="center">
                                        <v-col cols="12" sm="6">
                                          <v-btn color="primary" block :loading="isLoading"
                                            @click="approveIzin">Approve</v-btn>
                                          <v-btn color="red" block :loading="isLoading"
                                            @click="rejectIzin">Reject</v-btn>
                                          <v-btn color="blue darken-1" block
                                            @click="dialogDetail = false">Cancel</v-btn>
                                        </v-col>
                                      </v-row>
                                      <v-spacer></v-spacer>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>

                                <v-dialog v-model="dialogDetailSakit" max-width="5000px">
                                  <v-card>

                                    <v-card-text>
                                      <v-data-table v-model="dataDetail" :headers="headerDetail" :items="hasilDetail"
                                        :single-select="singleSelect" :search="searchDetail" :loading="isLoading"
                                        loading-text="Loading... Mohon Tunggu" item-key="ID_IZIN_SAKIT" show-select
                                        class="elevation-1">
                                        <template v-slot:top>
                                          <v-toolbar flat>
                                            <v-toolbar-title>Detail Izin</v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <v-col cols="4" sm="4">
                                              <v-text-field v-model="searchDetail" append-icon="mdi-magnify"
                                                label="Search" single-line hide-details></v-text-field>
                                            </v-col>
                                          </v-toolbar>
                                        </template>


                                        <!-- <template
                    v-slot:[`item.DESCRIPTION`]="{ item }">
                        
                        <a style="color:blue" v-if="item.DESCRIPTION == 'Sakit'"
                          href="#" @click="viewSurat(item)"
                          >{{item.DESCRIPTION}}</a>

                          <div v-else>
                            {{item.DESCRIPTION}}
                          </div>
                      </template> -->

                                        <template v-slot:[`item.actions`]="{ item }">
                                          <v-btn v-if="item.DESCRIPTION == 'Sakit'" class="mr-2"
                                            @click="viewSurat(item)" :loading="isLoading"><v-icon class="mr-2">
                                              mdi-image
                                            </v-icon>
                                            Gambar
                                          </v-btn>
                                        </template>

                                      </v-data-table>
                                    </v-card-text>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-row justify="center">
                                        <v-col cols="12" sm="6">
                                          <v-btn color="primary" block :loading="isLoading"
                                            @click="approveIzin">Approve</v-btn>
                                          <v-btn color="red" block :loading="isLoading"
                                            @click="rejectIzin">Reject</v-btn>
                                          <v-btn color="blue darken-1" block
                                            @click="dialogDetailSakit = false">Cancel</v-btn>
                                        </v-col>
                                      </v-row>
                                      <v-spacer></v-spacer>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>

                              </v-toolbar>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                              <v-btn v-show="item.jumlahizin > 0" class="mr-2" @click="getDetailIzin(item)"><v-icon
                                  class="mr-2">
                                  mdi-account-details
                                </v-icon>
                                Detail Izin
                              </v-btn>
                              <v-btn v-show="item.jumlahizinsakit > 0" class="mr-2"
                                @click="getDetailIzinSakit(item)"><v-icon class="mr-2">
                                  mdi-account-details
                                </v-icon>
                                Detail Izin Sakit
                              </v-btn>
                            </template>

                          </v-data-table>

                          <v-dialog v-model="dialogGambar" max-width="800px">
                            <v-card :loading="isLoading">
                              <v-form ref="form">
                                <v-card-title>
                                  <span class="headline">Surat Sakit</span>
                                </v-card-title>

                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12" sm="12" md="12">
                                        <v-img v-bind:src="'data:image/jpeg;base64,' +
                                          gambar
                                          "></v-img>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="blue darken-1" text @click="dialogGambar = false">
                                    OK
                                  </v-btn>
                                </v-card-actions>
                              </v-form>
                            </v-card>
                          </v-dialog>

                        </v-col>
                      </v-row>
                    </v-card>

                  </v-row>
                </div>
              </form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import CNavbar from '../../components/CNavbar.vue'
import CToolbar from '../../components/CToolbar.vue'
export default {
  components: { CToolbar, CNavbar },

  data() {
    return {

      isLoading: false,
      dialogDetail: false,
      dialogDetailSakit: false,
      singleSelect: false,
      dialogGambar: false,
      notifPindah : true,

      search: "",
      searchDetail: "",
      nikBawahan: "",
      tipe: "",
      gambar: "",
      tokenBawahan: "",

      headerApprove: [
        {
          text: "NIK",
          align: "start",
          sortable: true,
          value: "employeeid",
        },
        {
          text: "Nama",

          value: "employeename"
        },
        {
          text: "Jumlah Hari",
          value: "jumlahhari"
        },

        { text: 'Detail', value: 'actions', sortable: true },
      ],
      dataDetail: [],
      headerDetail: [
        {
          text: "NIK",
          align: "start",
          sortable: true,
          value: "EMPLOYEEID",
        },
        { text: "Jenis Izin", sortable: true, value: "DESCRIPTION" },
        { text: "Tanggal Awal Izin", sortable: true, value: "FROMD" },
        { text: "Tanggal Akhir Izin", sortable: true, value: "TOD" },
        { text: "Jam In", sortable: true, value: "TIMEIN" },
        { text: "Jam Out", sortable: true, value: "TIMEOUT" },
        { text: "Keterangan", sortable: true, value: "KETERANGAN" },
        { text: 'Document', value: 'actions', sortable: true },
      ],
      results: [],
      hasilDetail: [],

      itemsTableUser: [],
      editedIndex: -1,
      editedItem: [],
    }
  },

  mounted() {
    this.nik = this.user.data[0].NikKaryawan
    this.name = this.user.data[0].NamaKaryawan
    this.nik_atasan = this.user.data[0].NikAtasan
    this.nama_atasan = this.user.data[0].NamaAtasan
    this.Atasan = this.nik_atasan + '-' + this.nama_atasan
    this.notifPindah = true
    this.getDataIzin()
  },

  computed: {
    ...mapGetters({
      user: "user"
    }),
  },

  methods: {

    ...mapActions({
      getPendingIzin: "getPendingIzin",
      approveIzinApi: "approveIzinApi",
      rejectIzinApi: "rejectIzinApi",
      getDetailPendingIzin: "getDetailPendingIzin",
      getDataTokenFirebase: "getDataTokenFirebase",
      getImageIzinSakit: "getImageIzinSakit",
      messageFirebase: "messageFirebase",
    }),

    homePage(){
      this.$router.push({ name: 'Homepage' })
    },

    getDataIzin() {
      this.isLoading = true
      let data = {
        code: "6",
        nik: this.nik,
        nikpengaju: this.nik
      }

      this.getPendingIzin(data)
        .then(response => {
          this.isLoading = false
          let data = response.data;

          if (response.status = 'Success') {

            this.results = data
            // console.log(this.results);

            this.nikBawahan = this.results.employeeid
            this.tipe = this.results.absencetype

          } else if (response.Message == 'Token Required !!!!' && response.Code == 400) {
            this.$router.push({ name: "Login" });
            setTimeout(() =>
              window.location.reload(),

            )
          }
          else {
            this.$swal({
              title: "Perhatian!",
              icon: "warning",
              html: `Anda Tidak Memiliki Data Izin Bawahan.`
            })


          }

        })
        .catch(error => {
          this.isLoading = false
          this.$swal({
            title: "Gagal!",
            icon: "error",
            html: `Data gagal di Proses`
          })
        })
    },

    getDetailIzin(item) {
      this.dialogDetail = true
      this.hasilDetail = []
      this.isLoading = true


      let data = {
        code: "7",
        nik: this.nik,
        tipe: "",
        nikpengaju: item.employeeid
      }

      // console.log(item);

      this.getDetailPendingIzin(data)
        .then((response) => {
          let hasil = response.data
          let status = response.status
          let message = response.message

          if (status == "Success") {
            this.hasilDetail = hasil
            this.isLoading = false

          } else if (response.Message == 'Token Required !!!!' && response.Code == 400) {
            this.$router.push({ name: "Login" });
            setTimeout(() =>
              window.location.reload(),

            )
          }
          else {
            this.isLoading = false
            this.$swal({
              icon: "warning",
              title: "Perhatian!",
              html: `Tidak Memiliki Detail Izin`
            })
          }


          // if(status == 'Warning'){
          //   this.$swal({
          //     icon:"info",
          //     title:"Perhatian!",
          //     html:`${message}`
          //   })
          // }
        }).catch((error) => {
          this.isLoading = false
          this.$swal({
            icon: "error",
            title: "Perhatian!",
            html: `Detail Gagal Di tarik`
          })
        })
    },

    getDetailIzinSakit(item) {
      this.dialogDetailSakit = true
      this.hasilDetail = []
      this.isLoading = true


      let data = {
        code: "7",
        nik: this.nik,
        tipe: "S1",
        nikpengaju: item.employeeid
      }

      this.getDetailPendingIzin(data)
        .then((response) => {
          let hasil = response.data
          let status = response.status
          let message = response.message

          if (status == "Success") {
            this.hasilDetail = hasil
            this.isLoading = false

          } else if (response.Message == 'Token Required !!!!' && response.Code == 400) {
            this.$router.push({ name: "Login" });
            setTimeout(() =>
              window.location.reload(),

            )
          }
          else {
            this.isLoading = false
            this.$swal({
              icon: "warning",
              title: "Perhatian!",
              html: `Tidak Memiliki Detail Izin`
            })
          }


          // if(status == 'Warning'){
          //   this.$swal({
          //     icon:"info",
          //     title:"Perhatian!",
          //     html:`${message}`
          //   })
          // }
        }).catch((error) => {
          this.isLoading = false
          this.$swal({
            icon: "error",
            title: "Perhatian!",
            html: `Detail Gagal Di tarik`
          })
        })
    },

    getFirebaseApprove() {
      let data = {
        Data: {
          code: "2",
          username: this.nikBawahan
        }
      }

      this.getDataTokenFirebase(data)
        .then(res => {
          let data = res.Result;
          // console.log(data)
          let token_firebaseUser = data

          for (var i = 0; i < token_firebaseUser.length; i++) {

            let tokenFb = token_firebaseUser[i].TOKEN;

            this.tokenBawahan = tokenFb
            // break
            this.sendFirebaseApprove()

          }

        }).catch(err => {
          console.log(err);
        })
    },

    getFirebaseReject() {
      let data = {
        Data: {
          code: "2",
          username: this.nikBawahan
        }
      }

      this.getDataTokenFirebase(data)
        .then(res => {
          let data = res.Result;
          // console.log(data)
          let token_firebaseUser = data

          for (var i = 0; i < token_firebaseUser.length; i++) {

            let tokenFb = token_firebaseUser[i].TOKEN;

            this.tokenBawahan = tokenFb
            // 
            this.sendFirebaseReject()

          }

        }).catch(err => {
          console.log(err);
        })
    },

    sendFirebaseApprove() {
      let dataFirebase = {
        collapse_key: "green",
        to: this.tokenBawahan,
        notification:
        {
          body: "",
          body: `Yeayy!! Izin Atas Nama ${this.nik} - ${this.name} Telah Di Approve! `,
          title: "Notif Approve Izin",
          icon: "Indomaret.png"
        }

      }

      this.messageFirebase(dataFirebase)
        .then(response => {
          let data = response
          // console.log(response);
          this.clearForm()

        }).catch(error => {
          console.log(error);
        })
    },

    sendFirebaseReject() {
      let dataFirebase = {
        collapse_key: "green",
        to: this.tokenBawahan,
        notification:
        {
          body: `Maaf izin Atas Nama ${this.nik} - ${this.name} Di Reject! `,
          title: "Notif Reject Izin",
          icon: "Indomaret.png"
        }

      }

      this.messageFirebase(dataFirebase)
        .then(response => {
          let data = response
          // console.log(response);
          this.clearForm()

        }).catch(error => {
          console.log(error);
        })
    },

    viewSurat(item) {

      this.isLoading = true
      this.editedIndex = this.itemsTableUser.indexOf(item);
      this.editedItem = Object.assign({}, item);

      let data = {
        code: "8",
        id: item.ID_IZIN_SAKIT,
        nik: this.nik,
        nikpengaju: item.EMPLOYEEID
      }

      this.getImageIzinSakit(data)
        .then(response => {
          let data = response.data[0].SURAT_SAKIT
          this.gambar = data
          try {
            if(data.split(',').length == 2){
              this.gambar = data.split(',')[1];
            } else {
              this.gambar = data;
            }
          } catch (error) {
            
          }

          this.isLoading = false
          this.dialogGambar = true

        }).catch(error => {
          this.$swal({
            title: "Gagal!",
            icon: "error",
            html: `Mohon Maaf Server Sedang Error`
          })
          this.isLoading = false
        })

    },

    approveIzin() {
      // console.log(this.dataDetail);
      this.isLoading = true

      let izin = []

      //!Looping data kiriman
      this.dataDetail.forEach(data => {
        izin.push({
          code: "1",
          EMPLOYEEID: data.EMPLOYEEID,
          EMPLOYEENAME: data.EMPLOYEENAME,
          ABSENCETYPE: data.ABSENCETYPE,
          FROMD: data.FROMD,
          DESCRIPTION: data.DESCRIPTION,
          KETERANGAN: data.KETERANGAN,
          ID_IZIN_SAKIT: data.ID_IZIN_SAKIT

        })

      })

      // console.log(izin);

      if (izin <= 0) {
        this.$swal({
          title: "Perhatian!",
          icon: "warning",
          html: `Anda Belum Memilih Izin/Cuti, Silahkan
            Memilih dan Melakukan Approval/Reject Sesuai Kebutuhan`
        }).then(() => {
          this.isLoading = false
        })

      } else {

        this.approveIzinApi(izin)
          .then(response => {
            let data = response.data

            let status = response.status

            if (status == "Success") {
              this.$swal({
                title: "Sukses!",
                icon: "success",
                html: `Izin Berhasil Di Approve`
              }).then(() => {
                this.dialogDetail = false
                this.dialogDetailSakit = false
                this.isLoading = false
                this.dataDetail = []
                this.getFirebaseApprove()
                this.getDataIzin()

              })
            } else if (response.Message == 'Token Required !!!!' && response.Code == 400) {
              this.$router.push({ name: "Login" });
              setTimeout(() =>
                window.location.reload(),

              )
            }
            else {
              this.$swal({
                title: "Gagal!",
                icon: "error",
                html: `Izin Gagal Di Approve`
              })
              this.isLoading = false
            }

          }).catch(error => {
            this.$swal({
              title: "Gagal!",
              icon: "error",
              html: `Mohon Maaf Server Sedang Error`
            })
            this.isLoading = false
          })
      }

    },

    rejectIzin() {
      // console.log(this.dataDetail);
      this.isLoading = true
      let izin = []

      //!Looping data kiriman
      this.dataDetail.forEach(data => {
        izin.push({
          code: "2",
          EMPLOYEEID: data.EMPLOYEEID,
          EMPLOYEENAME: data.EMPLOYEENAME,
          ABSENCETYPE: data.ABSENCETYPE,
          FROMD: data.FROMD,
          DESCRIPTION: data.DESCRIPTION,
          KETERANGAN: data.KETERANGAN,
          ID_IZIN_SAKIT: data.ID_IZIN_SAKIT
        })

      })

      if (izin <= 0) {
        this.$swal({
          title: "Perhatian!",
          icon: "warning",
          html: `Anda Belum Memilih Izin/Cuti, Silahkan
            Memilih dan Melakukan Approval/Reject Sesuai Kebutuhan`
        }).then(() => {
          this.isLoading = false
        })

      } else {

        this.rejectIzinApi(izin)
          .then(response => {
            let data = response.data

            let status = response.status

            if (status == "Success") {
              this.$swal({
                title: "Sukses!",
                icon: "success",
                html: `Izin Berhasil Di Reject`
              }).then(() => {
                this.dialogDetail = false
                this.dialogDetailSakit = false
                this.isLoading = false
                this.dataDetail = []
                this.getFirebaseReject()
                this.getDataIzin()

              })
            } else if (response.Message == 'Token Required !!!!' && response.Code == 400) {
              this.$router.push({ name: "Login" });
              setTimeout(() =>
                window.location.reload(),

              )
            }
            else {
              this.$swal({
                title: "Gagal!",
                icon: "error",
                html: `Izin Gagal Di Reject`
              })
              this.isLoading = false
            }

          }).catch(error => {
            this.$swal({
              title: "Gagal!",
              icon: "error",
              html: `Mohon Maaf Server Sedang Error`
            })
            this.isLoading = false
          })
      }
    },

    clearForm() {
      window.location.reload();
      // this.alasan = "";
    },

  },
}
</script>