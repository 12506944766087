<template>
  <div>
    <c-toolbar />
    <c-navbar />

    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-card class="mx-auto elevation-11 py-4 px-2">
              <v-row justify="center">
                <v-img src="img/indomaret.png" aspect-ratio="3" max-width="220" position="end"></v-img>
                <!-- <v-col cols="4">
                </v-col>-->
              </v-row>

            <v-row>
      <v-dialog
      v-model="notifPindah"
      persistent
      max-width="700"
    >
      <v-card >
        <v-card-title class="text-h5" justify = "center">
          <v-icon
          color = "info"
          x-large>
            mdi-information
          </v-icon>
          PERHATIAN!
        </v-card-title>
        <v-card-text>
          Anda tidak dapat mengakses menu ini, silakan menginput izin / cuti melalui web portal Indomaret Group Rumah Keduaku pada link berikut dan masuk menggunakan akun ESS Anda.
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-text>
          <a href="https://portal.hrindomaret.com/" target="_blank" style=" color:blue"
              >https://portal.hrindomaret.com/</a>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading = "isLoading"
            color="blue"
            @click="homePage()"
            large
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

              <form class="pa-4">
                <div class="my-2">
                  <v-row justify="left">

                    <v-col cols="12" sm="6">
                      <v-menu ref="menu1" v-model="date1" :close-on-content-click="false"
                        :return-value.sync="formData.month" transition="scale-transition" offset-y max-width="290px"
                        min-width="auto">

                        <template v-slot:activator="{ on, attrs }">
                          <span> Tanggal Awal Izin :
                            <v-text-field v-model="tanggalAwal" hint="DD/MM/YYYY format"
                              :rules="[() => !!tanggalAwal || 'This field is required']" prepend-icon="mdi-calendar"
                              v-bind="attrs" v-on="on"></v-text-field>
                          </span>
                        </template>
                        <v-date-picker v-model="formData.month" no-title :max="new Date().toISOString().substr(0, 10)">
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="date1 = false">Batal</v-btn>
                          <v-btn text color="primary" @click="$refs.menu1.save(formData.month)">OK</v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>


                    <v-col cols="12" sm="6">
                      <v-menu ref="menu2" v-model="date2" :close-on-content-click="false"
                        :return-value.sync="formData.day" transition="scale-transition" offset-y max-width="290px"
                        min-width="auto">

                        <template v-slot:activator="{ on, attrs }">
                          <span> Tanggal Akhir Izin :
                            <v-text-field v-model="tanggalAkhir" hint="DD/MM/YYYY format"
                              :rules="[() => !!tanggalAkhir || 'This field is required']" prepend-icon="mdi-calendar"
                              v-bind="attrs" v-on="on"></v-text-field>
                          </span>
                        </template>
                        <v-date-picker v-model="formData.day" no-title :min="this.formData.month">
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="date2 = false">Batal</v-btn>
                          <v-btn text color="primary" @click="$refs.menu2.save(formData.day)">OK</v-btn>
                        </v-date-picker>
                      </v-menu>

                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="6">
                      <span> Jumlah Hari Izin :
                        <v-text-field v-model="calculateDays" solo readonly dense>

                        </v-text-field>
                      </span>
                    </v-col>





                    <v-col cols="12" sm="6">

                      <span> Keterangan : <v-textarea v-model="keterangan" auto-grow solo dense>
                        </v-textarea>
                      </span>

                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="6">
                      <span>Provinsi</span>
                      <v-autocomplete v-model="provinsi" :items="dataProvinsi" item-text="nama" item-value="id" dense
                        label="Silahkan Pilih Provinsi" outlined @change="getKabupaten"></v-autocomplete>
                    </v-col>



                    <v-col cols="12" sm="6">
                      <span>Kabupaten</span>
                      <v-autocomplete v-model="kabupaten" :items="dataKabupaten" item-text="nama" item-value="id" dense
                        label="Silahkan Pilih Kabupaten" outlined @change="getFaskes"></v-autocomplete>
                    </v-col>
                  </v-row>

                  <v-row>

                    <v-col cols="12" sm="6">
                      <span>Faskes</span>
                      <v-autocomplete v-model="faskesTarikan" :items="dataFaskes" item-text="NAMA_FASKES"
                        item-value="KODE_FASKES" dense label="Silahkan Pilih Faskes" return-object outlined
                        :disabled="faskesIsian == true"></v-autocomplete>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <span>Jika Klinik Tidak ada Centang Ini!</span>
                      <v-checkbox v-model="faskesIsian" hide-details class="shrink mr-2 mt-0"></v-checkbox>

                      <v-text-field :disabled="!faskesIsian" v-model="faskesManual"
                        label="Input Faskes Manual"></v-text-field>
                    </v-col>

                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="6">
                      <span> Alamat Berobat :
                        <v-text-field v-model="alamatBerobat" auto-grow
                          hint="Alamat Tempat Berobat Anda"></v-text-field>
                      </span>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <span> Nomer Telfon Klinik :
                        <v-text-field v-model="nomerTelfon" @click.right.prevent @keydown="keydown" @copy.prevent
                          @paste.prevent v-on:keypress="isNumber($event)" hint="Nomer Telfon Klinik"></v-text-field>
                      </span>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="6">
                      <span> Nama Dokter :
                        <v-text-field v-model="namaDokter" v-on:keypress="isLetter($event)"
                          hint="Nama Dokter"></v-text-field>
                      </span>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <h5 class="red--text">* Untuk Scan Document / Foto Mohon Menggunakan Applikasi CAMSCANNER!
                        (Download Applikasi Di
                        Playstore)</h5>
                      <div>
                        <span> Upload Surat Sakit :
                          <v-file-input @change="previewFiles" v-model="suratSakit" filled show-size :rules="rules" ref="fileInput" counter
                            accept="image/jpeg, image/png, image/jpg" prepend-icon="mdi-camera">Input Gambar Surat
                          </v-file-input>
                          <h5 class="red--text">* Size Maximal 1MB!</h5>
                          <h5 class="red--text">* File Upload Surat Sakit Berformat Gambar (JPEG,JPG,PNG)!</h5>
                        </span>

                      </div>
                    </v-col>
                  </v-row>

                  <v-row justify="center">
                    <v-col cols="12" sm="6">
                      <v-btn color="primary" block @click="submitDataIzinSakit()" :loading="isLoading"
                        :disabled="!validateInput">submit</v-btn>
                    </v-col>
                  </v-row>


                </div>
              </form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import CNavbar from '../../components/CNavbar.vue'
import CToolbar from '../../components/CToolbar.vue'
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
export default {
  name: "InputSuratIzin",

  components: {
    CNavbar,
    CToolbar
  },

  data() {
    return {

      rules: [
        v => !!v || 'File Harus Di isi',
        v => !v || v.size <= 1000000 || 'Data Tidak Boleh Lebih Dari 1 MB!',
        v => !v || v.type == 'image/jpeg' || v.type == 'image/png' || v.type == 'image/jpg' || 'File Hanya Boleh Image'


      ],

      date1: false,
      date2: false,
      isLoading: false,
      faskesIsian: false,
      codeInput: 2,
      notifPindah:false,

      startDate: "",
      endDate: "",
      keterangan: "",
      provinsi: "",
      kabupaten: "",
      faskesTarikan: "",
      faskesManual: "",
      alamatBerobat: "",
      nomerTelfon: "",
      namaDokter: "",
      jmlSuratDokter: "",
      jmlKunjungan: "",
      jmlIzin: "",
      tglAwal: "",
      tglAkhir: "",
      token_atasan: "",

      dataProvinsi: [],
      dataKabupaten: [],
      dataFaskes: [],
      suratSakit: [],
      suratSakit_E:[],

      formData: {
        month: [],
        day: [],
      },

    }
  },

  computed: {
    ...mapGetters({
      user: "user"
    }),

    //! Merging 2 tanggal dalam text field
    // dateRangeText(val) {
    //   if (this.formData.month.length <= 0) {
    //     return "";
    //   } else {
    //     let startDate = this.testReformatDate(new Date(this.formData.month[0]));
    //     let endDate = this.testReformatDate(new Date(this.formData.month[1]));
    //     let mergeDate = [startDate, endDate];

    //     this.tglAwal=startDate
    //     this.tglAkhir=endDate

    //     let temp = mergeDate.join(" ~ ");

    //     return temp;
    //   }
    // },

    tanggalAwal() {
      if (this.formData.month == "") {
        return ""
      } else {
        let startDate = this.testReformatDate(new Date(this.formData.month));

        this.tglAwal = startDate

        return startDate
      }
    },

    tanggalAkhir() {
      if (this.formData.day == "") {
        return ""
      } else {
        let endDate = this.testReformatDate(new Date(this.formData.day));

        this.tglAkhir = endDate

        return endDate
      }
    },

    calculateDays() {
      //       var minutes = 1000*60;
      //       var hours = minutes*60;
      //       var day = hours*24;

      // let numbDays = 1 + Math.round((this.tglAkhir - this.tglAwal)/day)
      // console.log(numbDays);

      // this.jmlIzin = numbDays

      if (this.formData.month.length <= 0) {
        return "";
      } else {

        var tanggalA = moment(this.tglAwal, "DDMMYYYY")
        var tanggalB = moment(this.tglAkhir, "DDMMYYYY")

        let hrIzin = tanggalB.diff(tanggalA, 'days') + 1

        this.jmlIzin = hrIzin
        // console.log(this.jmlIzin)

        return hrIzin
      }

    },



    validateInput() {

      if (this.faskesIsian == false) {
        return (
          this.jmlIzin != "" &&
          this.tglAwal != "" &&
          this.tglAkhir != "" &&
          this.provinsi != "" &&
          this.kabupaten != "" &&
          this.faskesTarikan.NAMA_FASKES != "" &&
          this.faskesTarikan.KODE_FASKES != "" &&
          this.keterangan != "" &&
          // this.faskesManual == ""&&
          this.alamatBerobat != "" &&
          this.nomerTelfon != "" &&
          this.namaDokter != "" &&
          // this.jmlSuratDokter != "" &&
          // this.jmlKunjungan != "" &&
          this.suratSakit != "" &&
          this.suratSakit_E != ""&&
          this.rules
        )
      } else if (this.faskesIsian = true) {
        return (
          this.jmlIzin != "" &&
          this.tglAwal != "" &&
          this.tglAkhir != "" &&
          this.provinsi != "" &&
          this.kabupaten != "" &&
          this.keterangan != "" &&
          // this.faskes.KODE_FASKES == "" &&
          this.faskesManual != "" &&
          // this.faskesIsian == true &&
          this.alamatBerobat != "" &&
          this.nomerTelfon != "" &&
          this.namaDokter != "" &&
          // this.jmlSuratDokter != "" &&
          // this.jmlKunjungan != "" &&
          this.suratSakit != "" &&
          this.suratSakit_E != ""&&
          this.rules
        )
      } else return true

    },

    // validateInputManual(){
    //   if (this.faskesIsian == true) {
    //       return(
    //     this.jmlIzin != "" &&
    //     this.tglAwal != "" &&
    //     this.tglAkhir != "" &&
    //     this.provinsi != "" &&
    //     this.kabupaten != "" &&
    //     // this.faskes.NAMA_FASKES == "" &&
    //     // this.faskes.KODE_FASKES == "" &&
    //     this.faskesManual != ""&&
    //     // this.faskesIsian == true &&
    //     this.alamatBerobat != "" &&
    //     this.nomerTelfon != "" &&
    //     this.namaDokter != "" &&
    //     // this.jmlSuratDokter != "" &&
    //     // this.jmlKunjungan != "" &&
    //     this.suratSakit != "" &&
    //     this.rules
    //   )
    //    }else return true
    // }
  },

  mounted() {
    this.nik = this.user.data[0].NikKaryawan
    this.name = this.user.data[0].NamaKaryawan
    this.nik_atasan = this.user.data[0].NikAtasan
    this.nama_atasan = this.user.data[0].NamaAtasan
    this.notifPindah = true
    this.getProvinsi()

  },

  methods: {

    keydown: function (e) {
      // console.log(e);
    },

    testReformatDate(val) {
      let tempDate = new Date(val);
      return `${tempDate.getDate()}/${tempDate.getMonth() + 1
        }/${tempDate.getFullYear()}`;
    },

    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z ]+$/.test(char)) return true; // Match with regex 
      else e.preventDefault(); // If not match, don't add to input text
    },

    isNumber(n) {
      let char = String.fromCharCode(n.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true; // Match with regex 
      else n.preventDefault(); // If not match, don't add to input text
    },

    ...mapActions({
      getProvinsiApi: "getProvinsiApi",
      getKabupatenApi: "getKabupatenApi",
      getFaskesApi: "getFaskesApi",
      getDataTokenFirebase: "getDataTokenFirebase",
      messageFirebase: "messageFirebase",
      inputIzinSakit: "inputIzinSakit"

    }),

    homePage(){
      this.$router.push({ name: 'Homepage' })
    },

    getProvinsi() {
      let data = {
        code: "1",
        nik: this.nik,
      }

      this.getProvinsiApi(data)
        .then((response) => {
          let data = response.data
          // console.log(data);
          this.dataProvinsi = data

          this.getKabupaten


        }).catch((error) => {
          alert("gagal")
        })
    },

    getKabupaten() {
      let data = {
        code: "2",
        id: this.provinsi,
        nik: this.nik,
      }

      this.getKabupatenApi(data)
        .then((response) => {
          let data = response.data

          this.dataKabupaten = data

          this.getFaskes


        }).catch((error) => {
          alert("gagal")
        })
    },

    getFaskes() {
      let data = {
        code: "3",
        id: this.kabupaten,
        nik: this.nik,
      }

      this.getFaskesApi(data)
        .then((response) => {
          let data = response.data

          this.dataFaskes = data



        }).catch((error) => {
          alert("gagal")
        })
    },

    getFirebase() {
      let data = {
        Data: {
          code: "2",
          username: this.nik_atasan
        }
      }

      this.getDataTokenFirebase(data)
        .then(res => {
          let data = res.Result;
          // console.log(data)
          let token_firebaseUser = data

          for (var i = 0; i < token_firebaseUser.length; i++) {

            let tokenFb = token_firebaseUser[i].TOKEN;

            this.token_atasan = tokenFb
            // break
            this.sendFirebaseIzinSakit()


          }

        }).catch(err => {
          // console.log(err);
        })
    },

    sendFirebaseIzinSakit() {
      let dataFirebase = {
        collapse_key: "green",
        to: this.token_atasan,
        notification:
        {
          body: `Permintaan Izin Sakit Baru! Atas Nama ${this.nik} - ${this.name}`,
          title: "Notif Izin Sakit",
          icon: "Indomaret.png"
        }

      }

      this.messageFirebase(dataFirebase)
        .then(response => {
          let data = response
          // console.log(response);


        }).catch(error => {
          // console.log(error);
        })
    },

    previewFiles(event) {
      // this.suratSakit = event.target.files[0];
      let rawImg;
      const file = document.querySelector('input[type=file]').files[0];
      // console.log(file.type);
      if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png'){

        if (file.size > 1024 * 1024) {
        this.suratSakit = [];
        this.$swal({
          title: "Gagal!",
          icon: "error",
          html: `File Size melebihi 1MB`
        })
      } else {
        const reader = new FileReader();

        reader.onloadend = () => {
          rawImg = reader.result;
          this.suratSakit_E = rawImg
        }
        reader.readAsDataURL(file);
      }
       
    } else {
        this.suratSakit = [];
        this.$swal({
          title: "Gagal!",
          icon: "error",
          html: `Format File tidak sesuai`
        })
      }
      
    },

    submitDataIzinSakit() {

      if (this.jmlIzin > 0) {


        this.isLoading = true

        if (this.calculateDays > 50) {
          this.$swal({
            title: "Perhatian!",
            icon: "warning",
            html: `Hari Izin Sakit Tidak Boleh Lebih Dari 50 hari`
          }).then(() => {
            this.isLoading = false
          })
        } else {
          if (this.faskesIsian == false) {
            //!untuk Send Data Dengan Cara Lebih Baik(Serta File Upload)

            // const formData = new FormData()

            // formData.set('code', "2")
            // formData.set('nik', this.nik)
            // formData.set('nama_karyawan', this.name)
            // formData.set('nikatasan', this.nik_atasan)
            // formData.set('keterangan', this.keterangan)
            // formData.set('tgl_awal', this.tglAwal)
            // formData.set('tgl_akhir', this.tglAkhir)
            // formData.set('nama_klinik[0]', this.faskesTarikan.NAMA_FASKES)
            // formData.set('alamat_klinik[0]', this.alamatBerobat)
            // formData.set('no_telp_klinik[0]', this.nomerTelfon)
            // formData.set('nama_dokter[0]', this.namaDokter)
            // formData.set('jmlkunjungan', "1")
            // formData.set('jmlharisuratdokter[0]', this.jmlIzin)
            // formData.set('jmlhariizin', this.jmlIzin)
            // formData.set('id_klinik[0]', this.faskesTarikan.KODE_FASKES)
            // formData.set('tglawalsurat[0]', this.tglAwal)
            // formData.set('tglakhirsurat[0]', this.tglAkhir)
            // formData.append('image[]', this.suratSakit)


            // console.log(this.suratSakit);

            // let data = formData

            let data = {
              code: "2",
              nik: this.nik,
              nama_karyawan: this.name,
              nikatasan: this.nik_atasan,
              keterangan: this.keterangan,
              tgl_awal: this.tglAwal,
              tgl_akhir: this.tglAkhir,
              nama_klinik: this.faskesTarikan.NAMA_FASKES,
              alamat_klinik: this.alamatBerobat,
              no_telp_klinik: this.nomerTelfon,
              nama_dokter: this.namaDokter,
              jmlkunjungan: "1",
              jmlharisuratdokter: this.jmlIzin,
              jmlhariizin: this.jmlIzin,
              id_klinik: this.faskesTarikan.KODE_FASKES,
              tglawalsurat: this.tglAwal,
              tglakhirsurat: this.tglAkhir,
              image: this.suratSakit_E,
              
            };

            // console.log(this.suratSakit);
            // console.log(data);

            this.inputIzinSakit(data)
              .then((response) => {
                let data = response.data
                let status = response.status
                let message = response.message

                if (status == "Success") {

                  // this.getFirebase()

                  this.$swal({
                    title: "Sukses!",
                    icon: "success",
                    html: `Izin Berhasil DiSubmit`
                  }).then(() => {

                    window.location.reload()

                  })
                  this.isLoading = false

                } else if (status == "File Error") {
                  this.$swal({
                    title: "Gagal!",
                    icon: "error",
                    html: `Format File tidak sesuai Atau Size melebihi 1MB  `
                  })
                  this.isLoading = false

                } else if (status == "Failed" && message == "Data gagal di proses, masih ada request yang pending pada tanggal tersebut") {
                  this.$swal({
                    title: "Gagal!",
                    icon: "error",
                    html: `Anda memiliki Request Izin dengan Status Pending Pada Tanggal Tersebut`
                  })
                  this.isLoading = false

                } else if (response.Message == 'Token Required !!!!' && response.Code == 400) {
                  this.$router.push({ name: "Login" });
                  setTimeout(() =>
                    window.location.reload(),

                  )
                } else {
                  this.$swal({
                    title: "Gagal!",
                    icon: "error",
                    html: `${message}`
                  })
                  this.isLoading = false
                }

              }).catch((error) => {
                this.$swal({
                  title: "Gagal!",
                  icon: "error",
                  html: `Data gagal di Proses`
                })
                this.isLoading = false
              })
          } else {
            //!untuk Send Data Dengan Cara Lebih Baik(Serta File Upload)
            // const formData = new FormData()

            // formData.set('code', "2")
            // formData.set('nik', this.nik)
            // formData.set('nama_karyawan', this.name)
            // formData.set('nikatasan', this.nik_atasan)
            // formData.set('keterangan', this.keterangan)
            // formData.set('tgl_awal', this.tglAwal)
            // formData.set('tgl_akhir', this.tglAkhir)
            // formData.set('nama_klinik[0]', this.faskesManual)
            // formData.set('alamat_klinik[0]', this.alamatBerobat)
            // formData.set('no_telp_klinik[0]', this.nomerTelfon)
            // formData.set('nama_dokter[0]', this.namaDokter)
            // formData.set('jmlkunjungan', "1")
            // formData.set('jmlharisuratdokter[0]', this.jmlIzin)
            // formData.set('jmlhariizin', this.jmlIzin)
            // formData.set('id_klinik[0]', "null")
            // formData.set('tglawalsurat[0]', this.tglAwal)
            // formData.set('tglakhirsurat[0]', this.tglAkhir)
            // formData.append('image[]', this.suratSakit)


            // console.log(this.suratSakit);

            // let data = formData


            let data = {
              code: "2",
              nik: this.nik,
              nama_karyawan: this.name,
              nikatasan: this.nik_atasan,
              keterangan: this.keterangan,
              tgl_awal: this.tglAwal,
              tgl_akhir: this.tglAkhir,
              nama_klinik: this.faskesManual,
              alamat_klinik: this.alamatBerobat,
              no_telp_klinik: this.nomerTelfon,
              nama_dokter: this.namaDokter,
              jmlkunjungan: "1",
              jmlharisuratdokter: this.jmlIzin,
              jmlhariizin: this.jmlIzin,
              id_klinik: "null",
              tglawalsurat: this.tglAwal,
              tglakhirsurat: this.tglAkhir,
              image: this.suratSakit_E,
            };
            

            this.inputIzinSakit(data)
              .then((response) => {
                let data = response.data
                let status = response.status
                let message = response.message

                if (status == "Success") {
                  this.getFirebase()
                  this.$swal({
                    title: "Sukses!",
                    icon: "success",
                    html: `Izin Berhasil DiSubmit`
                  }).then(() => {

                    window.location.reload()

                  })

                  this.isLoading = false
                } else if (status == "File Error") {
                  this.$swal({
                    title: "Gagal!",
                    icon: "error",
                    html: `Format File tidak sesuai Atau Size melebihi 1MB  `
                  })
                  this.isLoading = false

                } else if (status == "Failed" && message == "Data gagal di proses, masih ada request yang pending pada tanggal tersebut") {
                  this.$swal({
                    title: "Gagal!",
                    icon: "error",
                    html: `Anda memiliki Request Izin dengan Status Pending Pada Tanggal Tersebut`
                  })
                  this.isLoading = false

                } else if (response.Message == 'Token Required !!!!' && response.Code == 400) {
                  this.$router.push({ name: "Login" });
                  setTimeout(() =>
                    window.location.reload(),

                  )
                } else {
                  this.$swal({
                    title: "Gagal!",
                    icon: "error",
                    html: `Izin Gagal DiSubmit`
                  })
                  this.isLoading = false
                }

              }).catch((error) => {
                this.$swal({
                  title: "Gagal!",
                  icon: "error",
                  html: `Data gagal di Proses`
                })
                this.isLoading = false
              })
          }
        }

      } else {
        this.$swal({
          title: "Perhatian!",
          icon: "error",
          html: `Tanggal Izin Tidak Boleh Terbalik dan Jumlah Izin Tidak boleh minus`
        })
      }
    },

    clearIsian() {
      this.jmlIzin = ""
      this.startDate = ""
      this.endDate = ""
      this.provinsi = ""
      this.kabupaten = ""
      this.faskes.NAMA_FASKES = ""
      this.faskes.KODE_FASKES = ""
      this.faskes = ""
      this.faskesManual = ""
      this.alamatBerobat = ""
      this.nomerTelfon = ""
      this.namaDokter = ""
      this.jmlSuratDokter = ""
      this.formData.month.length = 0
      this.formData.month = []
      this.keterangan = ""
      // this.jmlKunjungan = "" 
      this.suratSakit = null
    },

  },
}
</script>