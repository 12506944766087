<template>
  <div>
    <c-toolbar />
    <c-navbar />
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-card class="mx-auto elevation-11" width="2000">
            <v-img src="img/indomaret.png" aspect-ratio="3" width="220" position="end"></v-img>

                   <v-row>
      <v-dialog
      v-model="notifPindah"
      persistent
      max-width="700"
    >
      <v-card >
        <v-card-title class="text-h5" justify = "center">
          <v-icon
          color = "info"
          x-large>
            mdi-information
          </v-icon>
          PERHATIAN!
        </v-card-title>
        <v-card-text>
          Anda tidak dapat mengakses menu ini, silakan menginput izin / cuti melalui web portal Indomaret Group Rumah Keduaku pada link berikut dan masuk menggunakan akun ESS Anda.
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-text>
          <a href="https://portal.hrindomaret.com/" target="_blank" style=" color:blue"
              >https://portal.hrindomaret.com/</a>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading = "isLoading"
            color="blue"
            @click="homePage()"
            large
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

            <h1 class="text-center mt-4">Riwayat Izin Karyawan</h1>

            <v-col cols="12" sm="4">
              <v-menu ref="menu" v-model="menu" transition="scale-transition" :close-on-content-click="false" offset-y
                max-width="290px" min-width="290px">
                <v-alert type="info">Jika Ingin Memilih 1 Hari Mohon Pilih Hari Yang Sama 2
                  Kali!</v-alert>

                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="dateRangeText" label="Pilih Range Hari" v-bind="attrs"
                    v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="formData.month" range>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">Batal</v-btn>
                  <v-btn text color="primary" @click="getRiwayat" :loading="btnLoading">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-card>
              <v-row>
                <v-col cols="12">
                  <v-card-title>
                    Izin Karyawan

                    <v-spacer></v-spacer>
                    <v-col cols="4" sm="4">
                      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                        hide-details></v-text-field>
                    </v-col>
                  </v-card-title>

                  <div v-if="showTable">
                    <v-data-table :headers="headers" :items="results" :search="search" :loading="isLoading"
                      class="elevation-1">
                      <template v-slot:[`item.APPROVEDDATE`]="{ item }">
                        {{
                          item.APPROVEDDATE == "1900-01-01 00:00:00"
                            ? " "
                            : formatDate(item.APPROVEDDATE)
                        }}
                      </template>
                      <template v-slot:[`item.CREATEDTIME`]="{ item }">
                        {{
                          formatDate(item.CREATEDTIME)
                        }}
                      </template>

                      <template v-slot:[`item.FROMD`]="{ item }">
                        {{
                          formatDate(item.FROMD)
                        }}
                      </template>
                      <template v-slot:[`item.STATUSIJIN`]="{ item }">
                        {{
                          item.STATUSIJIN == 0 &&
                            (item.ORGANIZATION_NUMBER.length < 5 || item.ORGANIZATION_NUMBER.match(/^.*F20F243700$/)) &&
                            (item.JAM_IN == "" || item.JAM_OUT == "") ? "Pending Menunggu Presensi" : item.STATUSIJIN == 0 &&
                              (item.ORGANIZATION_NUMBER.length < 5 || item.ORGANIZATION_NUMBER.match(/^.*F20F243700$/)) &&
                              item.JAM_IN == null && item.JAM_OUT == null ? "Pending Menunggu Presensi" : item.STATUSIJIN == 0 &&
                                item.ORGANIZATION_NUMBER.length > 5
                            ? "Pending Approval Atasan"
                            : item.STATUSIJIN == 2 &&
                              item.ACTIONBY == 'SYSTEM'
                              ? "Rejected By System"
                              : item.STATUSIJIN == 1
                                ? "Approved"
                                : item.STATUSIJIN == 0
                                  ? "Pending Approval Atasan"
                                  : item.STATUSIJIN == 2
                                    ? "Rejected"
                                    : item.STATUSIJIN == 3
                                    ? "Pending Approval HR"
                                    : "Null"
                        }}
                      </template>

                      <template v-slot:[`item.TIMEIN`]="{ item }">
                        {{
                          item.TIMEIN == "23:59"
                            ? " "
                            : item.TIMEIN
                        }}
                      </template>

                      <template v-slot:[`item.TIMEOUT`]="{ item }">
                        {{
                          item.TIMEOUT == "23:59"
                            ? " "
                            : item.TIMEOUT
                        }}
                      </template>
                    </v-data-table>
                  </div>

                  <div v-else>
                    <v-card :loading="loadingHasil">
                      <v-card-text v-show="!loadingHasil" class="text-center font-italic error--text">Please Completely
                        field the
                        form to show the
                        table</v-card-text>
                      <template slot="progress">
                        <v-card-text class="text-center">
                          <v-progress-circular color="primary" indeterminate></v-progress-circular>
                        </v-card-text>
                      </template>
                    </v-card>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Axios from "axios";
import { mapActions, mapGetters } from "vuex";
import CToolbar from "@/components/CToolbar.vue";
import moment from 'moment'

import CNavbar from "@/components/CNavbar.vue";
import axios from "axios";
export default {
  name: "Riwayat-Izin",
  components: {
    CToolbar,
    CNavbar,
  },

  data() {
    return {
      search: "",
      headers: [
        {
          text: "Tipe Izin",
          align: "start",
          sortable: true,
          value: "ABSENCETYPE",
        },
        { text: "Deskripsi", value: "DESCRIPTION" },
        { text: "Disetujui", value: "APPROVEDBY" },
        { text: "Tanggal Approve", value: "APPROVEDDATE" },
        { text: "Tanggal Permohonan", value: "CREATEDTIME" },
        { text: "Status Izin", value: "STATUSIJIN" },
        { text: "Dari Tanggal", value: "FROMD" },
        { text: "Jam Izin In", value: "TIMEIN" },
        { text: "Jam Izin Out", value: "TIMEOUT" },
        { text: "Keterangan Izin", value: "KETERANGAN" },
      ],
      startDate: "",
      endDate: "",
      results: [],
      nik: "",
      actionby: "",

      showFieldDay: false,
      menu: false,
      isLoading: false,
      loadingHasil: false,
      showTable: false,
      notifPindah : false,

      formData: {
        month: [],
      },
    };
  },

  mounted() {
    this.nik = this.user.data[0].NikKaryawan
    this.notifPindah = true
  },

  computed: {
    ...mapGetters({
      user: "user",
    }),

    dateRangeText(val) {
      if (this.formData.month.length <= 0) {
        return "";
      } else {
        let startDate = this.testReformatDate(new Date(this.formData.month[0]));
        let endDate = this.testReformatDate(new Date(this.formData.month[1]));
        let mergeDate = [startDate, endDate];
        let temp = mergeDate.join(" ~ ");
        return temp;
      }
    },
  },

  methods: {
    ...mapActions({
      riwayatIzin: "riwayatIzin"
    }),

    homePage(){
      this.$router.push({ name: 'Homepage' })
    },

    getRiwayat() {
      this.menu = false
      this.loadingHasil = true

      let data = {
        code: "4",
        nik: this.nik,
        tglawal: this.formData.month[0],
        tglakhir: this.formData.month[1],
        nikpengaju: this.nik
      }

      this.riwayatIzin(data)

        .then((response) => {
          let izin = response.data;
          let status = response.status
          this.loadingHasil = false

          if (status == "Success") {
            this.results = izin;
            this.actionby = response.data[0].ACTIONBY

            this.showTable = true
          } else if (status == "Warning") {
            this.$swal({
              icon: "warning",
              title: "Perhatian!",
              html: `Anda Tidak Memiliki Izin Dengan Range Tanggal Ini!`
            })
          } else if (response.Message == 'Token Required !!!!' && response.Code == 400) {
            this.$router.push({ name: "Login" });
            setTimeout(() =>
              window.location.reload(),

            )
          } else {
            this.$swal({
              icon: "error",
              title: "Oops!",
              html: `Gagal Mengambil Data Izin`
            })
          }
        })
        .catch((error) => {
          this.loadingHasil = false
          const izin = error;
          this.$swal({
            icon: "error",
            title: "Server Error!",
            html: `Terdapat Maintenance Atau Gangguan Jaringan Pada Server Silahkan Coba Lagi nanti`
          })
        });
    },

    formatDate(value) {

      return moment(value).format("DD-MM-YYYY HH:mm:ss")
    },

    testReformatDate(val) {
      let tempDate = new Date(val);
      return `${tempDate.getDate()}-${tempDate.getMonth() + 1
        }-${tempDate.getFullYear()}`;
    },
  },
};
</script>
