var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('c-toolbar'),_c('c-navbar'),_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto elevation-11",attrs:{"width":"2000"}},[_c('v-img',{attrs:{"src":"img/indomaret.png","aspect-ratio":"3","width":"220","position":"end"}}),_c('v-row',[_c('v-dialog',{attrs:{"persistent":"","max-width":"700"},model:{value:(_vm.notifPindah),callback:function ($$v) {_vm.notifPindah=$$v},expression:"notifPindah"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5",attrs:{"justify":"center"}},[_c('v-icon',{attrs:{"color":"info","x-large":""}},[_vm._v(" mdi-information ")]),_vm._v(" PERHATIAN! ")],1),_c('v-card-text',[_vm._v(" Anda tidak dapat mengakses menu ini, silakan menginput izin / cuti melalui web portal Indomaret Group Rumah Keduaku pada link berikut dan masuk menggunakan akun ESS Anda. ")]),_c('v-spacer'),_c('v-card-text',[_c('a',{staticStyle:{"color":"blue"},attrs:{"href":"https://portal.hrindomaret.com/","target":"_blank"}},[_vm._v("https://portal.hrindomaret.com/")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.isLoading,"color":"blue","large":""},on:{"click":function($event){return _vm.homePage()}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('h1',{staticClass:"text-center mt-4"},[_vm._v("Riwayat Izin Karyawan")]),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-menu',{ref:"menu",attrs:{"transition":"scale-transition","close-on-content-click":false,"offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Pilih Range Hari"},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-alert',{attrs:{"type":"info"}},[_vm._v("Jika Ingin Memilih 1 Hari Mohon Pilih Hari Yang Sama 2 Kali!")]),_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.formData.month),callback:function ($$v) {_vm.$set(_vm.formData, "month", $$v)},expression:"formData.month"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v("Batal")]),_c('v-btn',{attrs:{"text":"","color":"primary","loading":_vm.btnLoading},on:{"click":_vm.getRiwayat}},[_vm._v("OK")])],1)],1)],1),_c('v-card',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-title',[_vm._v(" Izin Karyawan "),_c('v-spacer'),_c('v-col',{attrs:{"cols":"4","sm":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),(_vm.showTable)?_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.results,"search":_vm.search,"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"item.APPROVEDDATE",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.APPROVEDDATE == "1900-01-01 00:00:00" ? " " : _vm.formatDate(item.APPROVEDDATE))+" ")]}},{key:"item.CREATEDTIME",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.CREATEDTIME))+" ")]}},{key:"item.FROMD",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.FROMD))+" ")]}},{key:"item.STATUSIJIN",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.STATUSIJIN == 0 && (item.ORGANIZATION_NUMBER.length < 5 || item.ORGANIZATION_NUMBER.match(/^.*F20F243700$/)) && (item.JAM_IN == "" || item.JAM_OUT == "") ? "Pending Menunggu Presensi" : item.STATUSIJIN == 0 && (item.ORGANIZATION_NUMBER.length < 5 || item.ORGANIZATION_NUMBER.match(/^.*F20F243700$/)) && item.JAM_IN == null && item.JAM_OUT == null ? "Pending Menunggu Presensi" : item.STATUSIJIN == 0 && item.ORGANIZATION_NUMBER.length > 5 ? "Pending Approval Atasan" : item.STATUSIJIN == 2 && item.ACTIONBY == 'SYSTEM' ? "Rejected By System" : item.STATUSIJIN == 1 ? "Approved" : item.STATUSIJIN == 0 ? "Pending Approval Atasan" : item.STATUSIJIN == 2 ? "Rejected" : item.STATUSIJIN == 3 ? "Pending Approval HR" : "Null")+" ")]}},{key:"item.TIMEIN",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.TIMEIN == "23:59" ? " " : item.TIMEIN)+" ")]}},{key:"item.TIMEOUT",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.TIMEOUT == "23:59" ? " " : item.TIMEOUT)+" ")]}}],null,true)})],1):_c('div',[_c('v-card',{attrs:{"loading":_vm.loadingHasil}},[_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loadingHasil),expression:"!loadingHasil"}],staticClass:"text-center font-italic error--text"},[_vm._v("Please Completely field the form to show the table")]),_c('template',{slot:"progress"},[_c('v-card-text',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":""}})],1)],1)],2)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }